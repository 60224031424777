<template>
  <div class="container">
    <div class="forms-container">
      <div class="signin-signup">
        <form action="#" class="sign-in-form">
          <h2 class="title">vbox</h2>
          <div class="input-field">
            <i class="fas fa-user"></i><input type="text" placeholder="username" v-model="loginForm.username" />
          </div>
          <div class="input-field">
            <i class="fas fa-lock"></i>
            <input type="password" id="pwd" placeholder="password" v-model="loginForm.password">
            <i class="fa fa-eye-slash" id="eye" @click="showPwd"></i>
          </div>
          <input type="submit" value="submit" class="btn solid" @click.prevent="handleLogin" />
        </form>

      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import './static/js/64d58efce2.js'

export default {
  name: 'login',
  mounted() {
    import('./static/js/script.js')
  },
  data() {
    return {
      loginForm: {
        username: undefined,
        password: undefined
      },
      registerForm: {
        username: undefined,
        password: undefined,
      },
      userIsExist: false
    }
  },
  methods: {
    showPwd (){
      var eye = document.getElementById("eye");
      var pwd = document.getElementById("pwd");

      if (pwd.type === 'password') {
        pwd.type = 'text';
        eye.className='fa fa-eye';
      } else {
        pwd.type = 'password';
        eye.className='fa fa-eye-slash'
      }
    },

    async handleLogin() {
      const isValid =
        this.loginForm.username !== undefined && this.loginForm.username?.trim() !== ''
        &&
        this.loginForm.password !== undefined && this.loginForm.password?.trim() !== ''

      if (!isValid) {
        return this.$notification.error({
          message: 'The verification fails. Please fill in the login information again',
          description: 'The user name and password cannot be empty',
          duration: 1
        })
      }

      store.dispatch('user/appLogin', this.loginForm).
        then(data => {
          this.$notification.success({
            message: 'Login successful',
            description: 'Coming soon',
            duration: 1
          })
          setTimeout(() => {
            this.$router.push('/')
          }, 1000)
        }
        ).
        catch()
    },
    async handleRegister() {
      const isValid =
        this.registerForm.username !== undefined && this.registerForm.username?.trim() !== ''
        &&
        this.registerForm.password !== undefined && this.registerForm.password?.trim() !== ''

      if (!isValid) {
        return this.$notification.error({
          message: 'The verification fails. Please fill in the login information again',
          description: 'The user name and password cannot be empty',
          duration: 1
        })
      }

      const { username, password } = this.registerForm
      const payload = {
        userName: username,
        password
      }
      const userIsExist = await this.$store.dispatch('user/checkUserIsExist', {
        userName: username
      })

      this.userIsExist = userIsExist
      if (!userIsExist) {
        this.$store.dispatch('user/appRegister', payload).then(response => {
          this.$notification.success({
            message: 'Registered successfully',
            description: 'Welcome to vbox'
          })
          this.$refs.toggleLoginRef?.click()
        })
      }
      else {
        this.registerForm.username = undefined
        this.$notification.error({
          message: 'Verification failed, please fill in the registration information again',
          description: 'The user name has already been registered, please re-enter the user name',
          duration: 1
        })
        this.$refs.inputRef.focus()
      }
    },
  }
}
</script>

<style scoped src="./static/css/style.css">

</style>
<style>
.container {
  /*background-color: #8cc8ff !important;*/
  /*background-image: url('/src/assets/images/backgroundImage.jpg') !important*/
  background-image: url("../../../src/assets/images/backgroundImage.jpg");
  background-size:100% 100%;
  background-repeat: no-repeat;
  background-attachment:fixed;
}
.input-field {
  margin: 16px !important;
}

.register-username-error {
  text-align: left;
  padding: 2px 0;
}
</style>
